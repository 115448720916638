<template>
  <section class="">
    <b-row>
      <!-- <buy-nft-modal :title="$t('Buy NFT')" id="buyNftModalId" />

      <sell-nft-modal :title="$t('Sell NFT')" id="sellNftModalId" /> -->

      <b-col sm="6" lg="4" v-for="nft in nfts" :key="nft.id">
        <card-nft-summary
          md="6"
          :lease="getLeaseObj(nft.leases[0])"
          :nft="nft"
          isInvest
          v-if="nft.status == $t('nftStatus')['ACTIVE']"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";

// import store from "@/store";
import { sync } from "vuex-pathify";
import CardNftSummary from "@/views/card/app-custom/card-nft-summary.vue";
// import BuyNftModal from "@/views/card/app-custom/BuyNftModal.vue";
// import SellNftModal from "@/views/card/app-custom/SellNftModal.vue";

import { getArrElemById } from "@/libs/helpers";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BLink,
    BButton,

    // custom cards
    CardNftSummary,
    // BuyNftModal,
    // SellNftModal,
  },
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
    nfts: sync("nfts/list"),
    buyNftModal: sync("modals/buyNftModal"),
    // portfolio: sync("nfts/portfolio"),
  },
  data() {
    return {};
  },
  methods: {
    getLeaseObj(lease) {
      if (lease && lease.id) {
        return getArrElemById(this.leases, "id", lease.id);
      } else {
        return null;
      }
    },
    userHasHolding(nft) {
      return true;
    },
  },
  setup() {},
  mounted() {},
  created() {},
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
